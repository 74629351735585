import React from "react";
import PageWrapper from "../components/PageWrapper";
import { Helmet } from "react-helmet";

const error404page = () => {
  return (
    <>
      <Helmet title="Page not found | Genify" defer={false} />
      <PageWrapper
        headerConfig={{
          theme: "light",
          align: "right",
          variant: "primary",
          isFluid: true,
          button: "null", // cta, account, null
        }}
        footerConfig={{
          style: "style1", //style1, style2
        }}
      >
        <h2
          className="title gr-text-3 mb-9"
          style={{ margin: "20% auto 50% auto", textAlign: "center" }}
        >
          Page was not found. Please navigate to another page.
        </h2>
      </PageWrapper>
    </>
  );
};
export default error404page;
